import { useState, useEffect } from 'react'

import SimpleAccordion from '../../components/Accordion'
import ProductCard from '../../components/Card'

import accordionList from '../../imports/FAQ'
import products from '../../imports/products'

import { FaCheck, FaTimes } from 'react-icons/fa'
import ImageSlider from 'react-image-comparison-slider'

import potesCabelon from '../../assets/potes-cabelon.png'
import warrantySeal from '../../assets/cabelon-selo-garantia.png'

import freeShippingIcon from '../../assets/images/shipping/frete-gratis-cabelon-icone.png'
import freeShipping from '../../assets/images/shipping/cabelon-frete-rapido-gratis.png'

import cabelonBefore from '../../assets/images/before-after/cabelon-antes.jpeg'
import cabelonAfter from '../../assets/images/before-after/cabelon-depois.jpeg'

import cabelonTestimony1 from '../../assets/images/testimony/cabelon-depoimentos.png'
import cabelonTestimony2 from '../../assets/images/testimony/cabelon-depoimentos-2.png'

import cabelon3MonthsBefore1 from '../../assets/images/before-after/90-dias/cabelon-3-meses-antes.png'
import cabelon3MonthsAfter1 from '../../assets/images/before-after/90-dias/cabelon-3-meses-depois.png'
import cabelon3MonthsBefore2 from '../../assets/images/before-after/90-dias/cabelon-antes-3-meses.png'
import cabelon3MonthsAfter2 from '../../assets/images/before-after/90-dias/cabelon-depois-3-meses.png'

import styles from './styles.module.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { HashLink } from 'react-router-hash-link'

export default function Home() {
  const [partner, setPartner] = useState('monetizze')

  useEffect(() => {
    function checkPartner() {
      if (window.location.pathname === '/braip') {
        setPartner('braip')
        return
      }

      setPartner('monetizze')
    }

    checkPartner()
  }, [partner])

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.row} id={styles.rowBanner}>
            <h1>
              TRAVE A QUEDA <span>AGORA</span> MESMO
              <br />
              PRODUTO NATURAL <span>MODERNO</span>
            </h1>

            <div className={styles.homeBannerTop}>
              <section>
                <img src={potesCabelon} alt="cabelon logomarca" />
              </section>

              <section className={styles.benefitList}>
                <ul>
                  <li>
                    <FaCheck className={styles.checkIcon} size={22} />
                    Reduz a queda
                  </li>
                  <li>
                    <FaCheck className={styles.checkIcon} size={22} />
                    Restaura os folículos mortos
                  </li>
                  <li>
                    <FaCheck className={styles.checkIcon} size={22} />
                    Fortalece os fios existentes
                  </li>
                  <li>
                    <FaCheck className={styles.checkIcon} size={22} />
                    Fórmula Inovadora
                  </li>
                  <li>
                    <FaCheck className={styles.checkIcon} size={22} />
                    Estimula o crescimento de novos fios
                  </li>
                </ul>
              </section>
            </div>

            <HashLink to="#styles_rowProducts__S2FJl">
              <button className={styles.buyButton}>Quero recuperar meus cabelos</button>
            </HashLink>
          </div>

          <div className={styles.row} id={styles.rowDifferences}>
            <h2 className={styles.subTitle}>PORQUE CABELON VALE A PENA</h2>

            <div className={styles.containerDifferences}>
              <section>
                <div>
                  <img width={200} src={potesCabelon} alt="cabelon logomarca" />
                </div>
                <div>
                  <ul>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Eficiente
                    </li>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Preço Acessível
                    </li>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Fórmula Natural
                    </li>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Não engorda
                    </li>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Entrega rápida
                    </li>
                    <li>
                      <FaCheck className={styles.checkIcon} size={20} /> Zero efeitos colaterais
                    </li>
                  </ul>
                </div>
              </section>
              <section>
                <div>
                  <ul>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Não é eficiente
                    </li>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Preço Absurdo
                    </li>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Cheiro ruim
                    </li>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Gelatina animal
                    </li>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Demora na entrega
                    </li>
                    <li>
                      <FaTimes className={styles.errorIcon} size={20} /> Efeitos colaterais
                    </li>
                  </ul>
                </div>
                <div>
                  <img width={200} src={potesCabelon} alt="cabelon logomarca"></img>
                </div>
              </section>
            </div>
          </div>

          <div className={styles.row} id={styles.rowBeforeAfter}>
            <h2 className={styles.subTitle}>TENHA RESULTADOS INCRÍVEIS</h2>
            <p className={styles.description}>
              Resultado do Rogério com <strong>2 meses</strong> de uso de CabelON
            </p>
            <div className={styles.containerDifferences}>
              <div style={{ width: 500, height: 450 }}>
                <ImageSlider
                  image1={cabelonAfter}
                  image2={cabelonBefore}
                  showHandle={false}
                  sliderWidth={5}
                  sliderColor="white"
                  handleColor="green"
                  handleBackgroundColor="white"
                  animate={true}
                  animationCycleDuration={3500}
                />
              </div>
            </div>
          </div>

          <div className={styles.row} id={styles.rowTestimony}>
            <h2 className={styles.subTitle}>QUEM USA RECOMENDA</h2>
            <p className={styles.description}>
              Produto <strong>NATURAL</strong> que supre todas as vitaminas que seu cabelo precisa.
            </p>
            <div className={styles.containerTestimony}>
              <img src={cabelonTestimony1} alt="CabelON depoimento 1" />
              <img src={cabelonTestimony2} alt="CabelON depoimento 2" />
            </div>
          </div>

          <div className={styles.row} id={styles.rowResults}>
            <h2 className={styles.subTitle}>
              GARANTIA DE
              <br />
              <span>RESULTADOS</span>
            </h2>
            <div className={styles.warrantySeal}>
              <img src={warrantySeal} alt="CabelON selo de garantia" />
              <p className={styles.description}>
                Se você usar corretamente por <strong>90 DIAS</strong> o CabelON e não tiver resultados, devolvemos seu
                dinheiro!
                <br />
                <br />
                <span className={styles.termsAlert}>* conforme descritos nos termos</span>
              </p>
            </div>
            <div className={styles.containerResults}>
              <section>
                <img src={cabelon3MonthsBefore1} alt="CabelON resultado 3 meses" />
                <img src={cabelon3MonthsAfter1} alt="CabelON resultado 3 meses" />
              </section>
              <section>
                <img src={cabelon3MonthsBefore2} alt="CabelON resultado 3 meses 2" />
                <img src={cabelon3MonthsAfter2} alt="CabelON resultado 3 meses 2" />
              </section>
            </div>
          </div>

          <div className={styles.row} id={styles.rowShipping}>
            <h2 className={styles.subTitle}>
              ENTREGA <span>GRÁTIS</span> e RÁPIDA
            </h2>
            <p className={styles.description}>
              Produto <strong>NATURAL</strong> que supre todas as vitaminas que seu cabelo precisa.
            </p>
            <div className={styles.containerShipping}>
              <div className={styles.shippingContent}>
                <img src={freeShipping} alt="CabelON frete rápido e grátis" />
                <div className={styles.shipingDescription}>
                  <img src={freeShippingIcon} alt="CabelON frete rápido e grátis" />
                  <p className={styles.description}>Garanta sua entrega gratuita por tempo limitado.</p>
                  <p className={styles.description}>
                    <strong>TOTALMENTE GRÁTIS!</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.row} id={styles.rowProducts}>
            <h2 className={styles.subTitle}>
              ESCOLHA O <span>MELHOR</span> TRATAMENTO
            </h2>
            <p className={styles.description}>Para melhores resultados recomendamos o tratamento a partir de 3 meses</p>
            <div className={styles.containerProducts}>
              <ProductCard data={products} partner={partner} />
            </div>
          </div>

          <div className={styles.row} id={styles.rowSAQ}>
            <h2 className={styles.subTitle}>PERGUNTAS FREQUENTES</h2>
            <div className={styles.containerSAQ}>
              <SimpleAccordion data={accordionList} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

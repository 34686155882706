import React, { useState, useEffect } from 'react'
import { TopHeader, Section, Logo, LinkTo } from './styles'

export default function Footer() {
  const [patch, setPatch] = useState('/')

  useEffect(() => {
    function checkPartner() {
      if (window.location.pathname.match(/\/braip.*/s)) {
        setPatch('/braip/')
        return
      }

      setPatch('/')
    }

    checkPartner()
  }, [patch])

  return (
    <TopHeader>
      <Section>
        <nav>
          <Logo to="/">
            CABEL
            <span>ON</span>
          </Logo>
        </nav>

        <LinkTo to={`${patch}termos-de-uso`}>
          <p>Termos de uso</p>
        </LinkTo>
        <LinkTo to={`${patch}aviso-legal`}>
          <p>Aviso Legal</p>
        </LinkTo>
        <LinkTo to={`${patch}politica-de-privacidade`}>
          <p>Política de Privacidade</p>
        </LinkTo>
      </Section>
    </TopHeader>
  )
}

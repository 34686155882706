import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { FaAngleUp } from 'react-icons/fa'

export default function SimpleAccordion(props) {
  return (
    <>
      {props.data.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<FaAngleUp />}>
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

import Footer from '../../components/Footer'
import HeaderSecondary from '../../components/HeaderSecondary'
import styles from './styles.module.css'

export default function Legal() {
  return (
    <>
      <HeaderSecondary />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.row} id={styles.rowBanner}>
            <h1>
              AVISO <span>LEGAL</span>
            </h1>

            <div className={styles.homeBannerTop}>
              É importante ressaltar que os resultados de um produto podem variar de pessoa para pessoa, e é necessário
              ter cautela ao interpretar os testemunhos de outros clientes, pois cada indivíduo é único e pode reagir de
              maneira diferente. Além disso, é fundamental buscar orientação profissional antes de realizar mudanças
              significativas na alimentação ou na rotina de exercícios físicos. Somente um médico ou um profissional de
              saúde pode avaliar com precisão se essas mudanças são indicadas para você e se estão alinhadas com suas
              necessidades e limitações. As informações compartilhadas neste site têm caráter educativo e explicativo, e
              não devem ser consideradas como substitutas de uma consulta médica ou nutricional. Em caso de dúvidas, é
              recomendado entrar em contato com os responsáveis pelo produto, que estarão disponíveis para prestar
              esclarecimentos e orientações adicionais.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

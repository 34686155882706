import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './pages/Home'
import Terms from './pages/Terms'
import Legal from './pages/Legal'
import Privacity from './pages/Privacity'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/braip" component={Home} />

        <Route exact path="/termos-de-uso" component={Terms} />
        <Route exact path="/braip/termos-de-uso" component={Terms} />

        <Route exact path="/aviso-legal" component={Legal} />
        <Route exact path="/braip/aviso-legal" component={Legal} />

        <Route exact path="/politica-de-privacidade" component={Privacity} />
        <Route exact path="/braip/politica-de-privacidade" component={Privacity} />
      </Switch>
    </BrowserRouter>
  )
}

import Footer from '../../components/Footer'
import HeaderSecondary from '../../components/HeaderSecondary'
import styles from './styles.module.css'

export default function Privacity() {
  return (
    <>
      <HeaderSecondary />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.row} id={styles.rowBanner}>
            <h1>
              POLITICA DE <span>PRIVACIDADE</span>
            </h1>

            <div className={styles.homeBannerTop}>
              A privacidade de nossos usuários é extremamente importante para nós. Nós nos comprometemos a respeitar a
              privacidade de qualquer informação pessoal que possa ser coletada em nosso site ou em outros sites que
              possuímos e operamos. Somente solicitamos informações pessoais quando é realmente necessário para fornecer
              nossos serviços, e fazemos isso de forma justa e legal, com seu conhecimento e consentimento. Nós
              informamos claramente por que estamos coletando informações pessoais e como as utilizaremos. Somente
              retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Todos os dados
              são armazenados de maneira segura, com medidas comercialmente aceitáveis para evitar perda, roubo e
              acesso, divulgação, cópia, uso ou modificação não autorizados. Nós nunca compartilhamos informações de
              identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei. É importante lembrar
              que nosso site pode conter links para outros sites, e não temos controle sobre as práticas de privacidade
              desses sites. Utilizamos cookies para melhorar a experiência de nossos usuários. Esses cookies são
              pequenos arquivos que são baixados em seu computador. Eles coletam informações sobre como você usa nosso
              site, por exemplo, quais páginas você visita com mais frequência e quanto tempo você permanece em nosso
              site. Essas informações nos ajudam a melhorar nosso site e oferecer um serviço melhor e mais
              personalizado. Você pode impedir a configuração de cookies ajustando as configurações do seu navegador. No
              entanto, a desativação de cookies pode afetar a funcionalidade deste e de muitos outros sites que você
              visita. Por isso, recomendamos que você não desative os cookies. Caso tenha alguma dúvida sobre como
              lidamos com dados do usuário e informações pessoais, entre em contato conosco. Estamos sempre prontos para
              ajudar.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

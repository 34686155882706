const accordionList = [
  {
    title: 'COMO POSSO USAR O CABELON?',
    content: 'Recomendando ingerir uma cápsula pela manhã e uma a noite. Ou duas cápsulas em dose única durante o dia.'
  },

  {
    title: 'A PARTIR DE QUANTOS DIAS CONSIGO VER OS RESULTADOS?',
    content:
      'Os resultados variam de organismo para organismos e começam a aparecer nos primeiro mês de uso, desde que haja uso correto e continuo do produto.'
  },

  {
    title: 'TEM REGISTRO NA ANVISA',
    content:
      'O CabelON é um suplemento natural, e está acordo com a RDC 27/2010 e RDC 240/2018, sendo assim de acordo com as normas e dispensado de registro.'
  },

  {
    title: 'VOU TER EFEITO COLATERAL?',
    content:
      'Não, com vários testes realizados não tivemos nenhum caso de efeitos colaterais. Contudo cada organismo reage de maneira diferente aos componentes da fórmula.'
  },

  {
    title: 'QUALQUER PESSOA PODE USAR?',
    content:
      'Nossa fórmula é 100% natural então não tem contraindicações, caso surja alguma dúvida, procure por um médico.'
  },

  {
    title: 'TENHO SEGURANÇA AO COMPRAR NO SITE?',
    content:
      'Nosso site possui o certificado SSL que garante proteção a todos seus dados inseridos no momento da compra. Então é totalmente seguro efetuar sua compra. Respeitamos e prezamos pela proteção de dados através da lei LGPD.'
  },

  {
    title: 'QUAIS FORMAS DE PAGAMENTO?',
    content: 'O pagamento pode ser feito através de boleto, pix ou cartão de crédito em até 12x.'
  },

  {
    title: 'O FRETE É GRÁTIS? QUAL A PREVISÃO DE ENTREGA?',
    content:
      'O frete fica por nossa conta. E sobre a entrega varia de acordo com a região, podendo levar de 1 a 15 dias.'
  }
]

export default accordionList

import React, { useState, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import { TopHeader, Section, Logo, Button } from './styles'

export default function Header() {
  const [patch, setPatch] = useState('/')

  useEffect(() => {
    function checkPartner() {
      if (window.location.pathname.match(/\/braip.*/s)) {
        setPatch('/braip')
        return
      }

      setPatch('/')
    }

    checkPartner()
  }, [patch])
  return (
    <TopHeader>
      <Section>
        <nav>
          <Logo to={patch}>
            CABEL<span>ON</span>
          </Logo>
        </nav>

        <HashLink to="#styles_rowProducts__S2FJl">
          <Button>Compre Agora</Button>
        </HashLink>
      </Section>
    </TopHeader>
  )
}

import React, { useState, useEffect } from 'react'
import { TopHeader, Section, Logo } from './styles'

export default function HeaderSecondary() {
  const [patch, setPatch] = useState('/')

  useEffect(() => {
    function checkPartner() {
      if (window.location.pathname.match(/\/braip.*/s)) {
        setPatch('/braip')
        return
      }

      setPatch('/')
    }

    checkPartner()
  }, [patch])
  return (
    <TopHeader>
      <Section>
        <nav>
          <Logo to={patch}>
            CABEL<span>ON</span>
          </Logo>
        </nav>
      </Section>
    </TopHeader>
  )
}

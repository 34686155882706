const protocol = window.location.protocol

const products = [
  {
    product: `1 UN`,
    image_url: `/products/cabelon-unico-pote.png`,
    monetizze_url: `${protocol}//app.monetizze.com.br/checkout/DHA200506`,
    braip_url: `${protocol}//ev.braip.com/checkout/plage4lv/chenpnne`,
    mercado_livre_url: `https://produto.mercadolivre.com.br/MLB-3655465296-cabelon-60-capsulas-500mg-cabelon-_JM`,
    discount: false,
    best_sellers: false
  },
  {
    product: `3 UN`,
    image_url: `/products/cabelon-unico-pote.png`,
    monetizze_url: `${protocol}//app.monetizze.com.br/checkout/DNS203115`,
    braip_url: `${protocol}//ev.braip.com/checkout/plaoek6j/che9krr6`,
    mercado_livre_url: `https://produto.mercadolivre.com.br/MLB-3655542708-kit-3-cabelon-60-capsulas-500mg-cabelon-_JM`,
    discount: true,
    best_sellers: false
  },
  // {
  //   product: `6 UN`,
  //   image_url: `/products/cabelon-unico-pote.png`,
  //   monetizze_url: `${protocol}//app.monetizze.com.br/checkout/DZV203117`,
  //   braip_url: `${protocol}//ev.braip.com/checkout/plaqek00/che0vppn`,
  //   mercado_livre_url: `https://produto.mercadolivre.com.br/MLB-3655578522-kit-6-cabelon-60-capsulas-500mg-cabelon-_JM`
  //   discount: true,
  // best_sellers: false
  // },
  {
    product: `12 UN`,
    image_url: `/products/cabelon-unico-pote.png`,
    monetizze_url: `${protocol}//app.monetizze.com.br/checkout/DUK203119`,
    braip_url: `${protocol}//ev.braip.com/checkout/plaz1k0m/cher4ppz`,
    mercado_livre_url: `https://produto.mercadolivre.com.br/MLB-3655555794-kit-12-cabelon-60-capsulas-500mg-cabelon-_JM`,
    discount: true,
    best_sellers: true
  },
  {
    product: `24 UN`,
    image_url: `/products/cabelon-unico-pote.png`,
    monetizze_url: `${protocol}//#`,
    braip_url: `${protocol}//#`,
    mercado_livre_url: `https://produto.mercadolivre.com.br/MLB-3840275100-kit-24-cabelon-braip-_JM`,
    discount: true,
    best_sellers: false
  }
]

export default products

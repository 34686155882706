import Footer from '../../components/Footer'
import HeaderSecondary from '../../components/HeaderSecondary'
import styles from './styles.module.css'

export default function Terms() {
  return (
    <>
      <HeaderSecondary />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.row} id={styles.rowBanner}>
            <h1>
              TERMOS <span>DE USO</span>
            </h1>

            <div className={styles.homeBannerTop}>
              termos e condições aqui estabelecidos. Ao acessar esta página de vendas, o usuário está concordando com
              todas as regras e restrições de uso apresentadas, bem como com as leis aplicáveis. É importante ressaltar
              que o responsável por este site se reserva o direito de modificar os termos e condições a qualquer
              momento, sem aviso prévio, sendo responsabilidade do usuário manter-se atualizado quanto às mudanças que
              possam ocorrer. O usuário também é responsável por garantir que todas as informações fornecidas sejam
              verdadeiras, precisas e completas, além de manter seus dados atualizados. O acesso às áreas restritas do
              site é permitido apenas a pessoas autorizadas e com as devidas credenciais de login e senha. O usuário é
              responsável pela segurança de suas informações e não deve compartilhar suas credenciais com terceiros.
              Este site não se responsabiliza por eventuais problemas decorrentes do ambiente virtual, tais como
              interrupções, interceptações, invasões, disseminação de vírus ou outros atos ilícitos, que possam ocorrer
              sem a intenção deliberada dos administradores. Além disso, este site não é responsável pelo conteúdo de
              outros websites indicados ou acessados por meio deste, podendo eliminar o link ou direcionamento a
              qualquer momento, sem aviso prévio. Por fim, todas as informações, conteúdos e criações presentes nesta
              página de vendas são propriedades intelectuais privadas, protegidas por lei, e não podem ser copiadas,
              reproduzidas, modificadas, publicadas, atualizadas, postadas, transmitidas ou distribuídas sem autorização
              prévia. O uso das marcas registradas só é permitido com autorização expressa e toda violação de direitos
              autorais deve ser notificada ao suporte do produto. O acesso e uso desta página de vendas é regido pelas
              leis brasileiras e o usuário é responsável pelo cumprimento das leis locais aplicáveis. Ao aceitar estes
              termos e condições, o usuário concorda em cumprir todas as leis e regulamentos aplicáveis.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 220px;
  height: 435px;
  color: white;
  margin: 0 10px;
  border: 3px solid #228b22;
  border-bottom: none;
  border-radius: 20px;
  transition: all 0.5s;

  :hover {
    margin-bottom: 10px;
    box-shadow: 0px 0px 20px #0fa;
  }

  :first-child {
    height: 380px;
  }

  :nth-child(1) {
    #product {
      height: 83%;
    }
  }

  @media (max-width: 1080px) {
    margin-bottom: 20px;
  }
`
export const ContainerBestSellers = styled.div`
  width: 250px;
  height: 470px;
  color: white;
  margin: 0 10px;
  border: 3px solid #1e90ff;
  border-bottom: none;
  border-radius: 20px;
  transition: all 0.5s;

  :hover {
    margin-bottom: 10px;
    box-shadow: 0px 0px 25px #1e90ff;
  }

  @media (max-width: 1080px) {
    margin-bottom: 20px;
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`

export const ContainerDiscount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

export const ContainerDiscountBestSellers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerQtdProduct = styled.div`
  display: flex;
  flex-direction: row-reverse;

  margin: 0 0 20px 0;
`

export const Product = styled.div`
  height: 73%;
`

export const ProductBestSellers = styled.div`
  height: 74%;
`

export const ContentProduct = styled.div`
  background-image: url('/products/speechBubbleB.png');
  background-repeat: no-repeat;

  width: 95px;
  height: 90px;
  display: flex;
  align-items: center;
  text-align: center;

  position: relative;
  top: -80px;
  right: -110px;
`

export const ContentProductBestSellers = styled.div`
  background-image: url('/products/speechBubbleBestSellers.png');
  background-repeat: no-repeat;

  width: 95px;
  height: 90px;
  display: flex;
  align-items: center;
  text-align: center;

  position: relative;
  top: -85px;
  right: -140px;
`

export const QtdProduct = styled.strong`
  font-size: 20px;
  text-shadow: 1px 1px black;
  margin: 10px auto;
`

export const Button = styled.button`
  width: 103%;
  height: 40px;
  background-color: #32cd32;
  color: white;
  font-weight: bold;

  border: none;
  border-radius: 12px;

  transition: all 0.3s;
  :hover {
    background-color: white;
    color: #32cd32;
  }
`

export const ButtonBestSellers = styled.button`
  width: 103%;
  height: 43px;
  background-color: #1e90ff;
  color: white;
  font-weight: bold;

  border: none;
  border-radius: 12px;

  transition: all 0.3s;
  :hover {
    background-color: white;
    color: #1e90ff;
  }
`

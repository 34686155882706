import {
  Container,
  ContainerDiscount,
  QtdProduct,
  Button,
  Content,
  Product,
  ContentProduct,
  ContainerBestSellers,
  ButtonBestSellers,
  ProductBestSellers,
  ContentProductBestSellers,
  ContainerDiscountBestSellers
} from './styles'

export default function ProductCard(props) {
  const partner_url = props.partner === 'braip' ? 'braip_url' : 'monetizze_url'

  return (
    <>
      {props.data.map((item, index) =>
        item.best_sellers ? (
          <ContainerBestSellers key={index}>
            <Content>
              <ContainerDiscountBestSellers>
                <img
                  width={70}
                  height={70}
                  src="/products/selo-vendido.png"
                  alt="Mais vendido"
                  style={{ rotate: '-35deg', marginLeft: '5px' }}
                />
                {item.discount ? (
                  <img width={70} src="/products/percentBlue.png" alt="Desconto" style={{ marginRight: '10px' }} />
                ) : (
                  ``
                )}
              </ContainerDiscountBestSellers>
              <ProductBestSellers>
                <img width={'100%'} src={item.image_url} alt={item.product} style={{ marginRight: '10px' }} />
                <ContentProductBestSellers>
                  <QtdProduct>{item.product}</QtdProduct>
                </ContentProductBestSellers>
              </ProductBestSellers>
              <a
                href={item.mercado_livre_url}
                target="_blank"
                style={{ display: 'contents' }}
                rel="noopener noreferrer"
              >
                <ButtonBestSellers>COMPRAR AGORA!</ButtonBestSellers>
              </a>
            </Content>
          </ContainerBestSellers>
        ) : (
          <Container key={index}>
            <Content>
              <ContainerDiscount>
                {item.discount ? (
                  <img width={70} src="/products/percentGreen.png" alt="Desconto" style={{ marginRight: '10px' }} />
                ) : (
                  ``
                )}
              </ContainerDiscount>
              <Product id="product">
                <img width={'100%'} src={item.image_url} alt={item.product} />
                <ContentProduct>
                  <QtdProduct>{item.product}</QtdProduct>
                </ContentProduct>
              </Product>
              <a
                href={item.mercado_livre_url}
                target="_blank"
                style={{ display: 'contents' }}
                rel="noopener noreferrer"
              >
                <Button>COMPRAR AGORA!</Button>
              </a>
            </Content>
          </Container>
        )
      )}
    </>
  )
}

import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import Routes from './routes'
import GlobalStyle from './styles/global'

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-N6N7LMD' })
  }, [])

  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  )
}

export default App

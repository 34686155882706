import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const TopHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  height: 65px;
  background-color: #000;
  color: #fff;
  margin: 10px auto;
`

export const Section = styled.section`
  padding: 0 18px;
  width: 100%;
  max-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Logo = styled(Link)`
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;

  span {
    color: green;
    border: 3px solid green;
    border-radius: 22px;
    padding: 2px;
  }
`

export const LinkTo = styled(Link)`
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;

  :hover {
    color: green;
  }
`

export const Button = styled.button`
  background-color: #fafafa;
  padding: 8px 13px;
  font-weight: bold;
  border: none;
  font-size: 18px;
  border-radius: 4px;

  :hover {
    background-color: green;
    color: #fff;
  }
`
